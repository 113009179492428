/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "~src/variables.scss";

html {
    overflow-y: scroll;
}

body {
  background: $background-primary;
  box-sizing: border-box;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #F14F11 !important;
  stroke-opacity: 0.77;
}

.mat-ink-bar {
  background-color: $accent !important;
}

.mat-card {
  background: $background-primary-light;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

section.page-content {
  margin-top: 5vw;
  margin-left: 15%;
  width: 70%;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 767px) {
  section.page-content {
    margin-left: 5%;
    width: 90%
  }
}

section.page-content .title {
  padding-left: 1vw;
  color: $text-contrast;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 32px;
}
@media (max-width: 767px) {
  section.page-content .title {
    font-size: 24px;
    padding-left: 10px;
  }
}

.mat-card.paragraph-card {
  display: flexbox;
  margin-top: 10px;
  box-shadow: none;
  border-left: 2px solid $accent;
  padding: 30px;
}

.mat-card.paragraph-card {
  color: $text-contrast;
}

.mat-card-footer{
    margin: 0 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


section.page-content {
  -webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein .5s; /* Firefox < 16 */
      -ms-animation: fadein .5s; /* Internet Explorer */
       -o-animation: fadein .5s; /* Opera < 12.1 */
          animation: fadein .5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}