// Import material theming functions
@import '~@angular/material/theming';
@import 'theme.scss';

/* Custom Theme */
$custom-theme-background-primary: mat-palette($theme-background-primary-colors, main, light, dark);
$custom-theme-background-accent: mat-palette($theme-background-accent-colors, main, light, dark);

/* Colors */
$app-primary: mat-palette($mat-blue-gray, 300);
$app-accent: mat-palette($mat-teal, A400);
$app-warn: mat-palette($mat-pink);

$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);


/* Backgrounds */
$app-background-primary: mat-palette($custom-theme-background-primary, main);
$app-background-primary-light: mat-palette($custom-theme-background-primary, light);
$app-background-accent: mat-palette($custom-theme-background-accent, main);

$background-primary: mat-color($app-background-primary);
$background-primary-light: mat-color($app-background-primary-light);
$background-accent: mat-color($app-background-accent);


/* Text */
$app-text-primary: mat-palette($mat-blue-gray, 900);
$app-text-accent: mat-palette($mat-teal, A400);
$app-text-contrast: mat-palette($mat-blue-gray, 600);

$text-primary: mat-color($app-text-primary);
$text-accent: mat-color($app-text-accent);
$text-contrast: mat-color($app-text-contrast);
